import { LoadingOutlined } from '@ant-design/icons';
import { QueryClient, useQuery } from '@tanstack/react-query';
import { Select, Spin } from 'antd';
import { debounce } from 'lodash';
import { GetServerSideProps } from 'next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { getSession } from 'next-auth/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import ChevronLeft from '../../assets/icons/ChevronLeft';
import ChevronRight from '../../assets/icons/ChevronRight';
import CuriRocketBigFlameSvg from '../../assets/icons/CuriRocketBigFlameSvg';
import CuriRocketSvg from '../../assets/icons/CuriRocketSvg';
import Logo from '../../assets/icons/Logo';
import StarStruckSvg from '../../assets/icons/StarStruckSvg';
import theme from '../../assets/theme';
import { Input } from '../../components/_atoms/_Input';
import { Button } from '../../components/_atoms/Button';
import { LoadingDots } from '../../components/_atoms/DismissableQuestionList/DismissableQuestionList';
import { IconWrapper } from '../../components/_atoms/IconWrapper';
import TenantTypeAhead from '../../components/_atoms/TenantTypeAhead';
import PageContentContainer from '../../components/styled/PageContentContainer';
import { useBreakPoint } from '../../hooks/useBreakPoint';
import { useMetrics } from '../../hooks/useMetrics';
import { CountrySelector } from '../../hooks/usePaywallPrompt';
import useSignIn from '../../hooks/useSignIn';
import useSignUp from '../../hooks/useSignup';
import { useMarketingMetrics } from '../../providers/PlausibleAnalyticsProvider';
import backendService from '../../services/backendService';
import {
  DatabaseSchool,
  GradeType,
  IntendedUseType,
  SchoolRoleType,
  SignUpOriginType,
  SubjectType,
} from '../../services/backendService/types';
import { detectAndBestEffortSetLanguage } from '../../utils/localisationUtils';
import { dehydrateReactQueryPrefetchedInfiniteQuery } from '../../utils/react-query-utils';
import { provisionFlags } from '../../utils/ServerSideFeatureFlagUtils';
import { configureStore } from '../../utils/store';
import { zIndexes } from '../../utils/zIndexes';
type CurrentForm =
  | 'role'
  | 'country'
  | 'selectSchool'
  | 'subjects'
  | 'grades'
  | 'intendedUses'
  | 'signUpOrigin'
  | 'studentJoinForm';

type ActiveFormSection = {
  title: string;
  options?: string[];
  canMultiSelect?: boolean;
  placeHolderText: string;
  label: string;
};

const SignupTopbar = () => {
  return (
    <TopbarContainer>
      <Link href="/" passHref>
        <IconWrapper color={theme.colors.white} height={'22px'} width={'81px'}>
          <Logo />
        </IconWrapper>
      </Link>
      <h3 style={{ textAlign: 'center', margin: 0, color: theme.colors.white }}>
        <Login />
      </h3>
    </TopbarContainer>
  );
};

const TopbarContainer = styled.div`
  z-index: ${zIndexes('TOPBAR')};
  width: 100%;
  height: 60px;
  flex: 0;
  padding: 10px 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: ${theme.colors.white};
  background-color: ${theme.colors.orange};
  position: fixed;
  top: 0;
  margin: auto;
  flex-wrap: wrap;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12);
`;

type FormSection = {
  title: string;
  options: string[];
  canMultiSelect?: boolean;
  placeHolderText: string;
  label: string;
};

type ActiveFormSectionMapper = {
  [key in Exclude<
    CurrentForm,
    'selectSchool' | 'country' | 'studentJoinForm'
  >]: FormSection;
};

const activeFormSectionMapper: ActiveFormSectionMapper = {
  role: {
    title: 'What is your primary role?',
    options: [
      'Teacher',
      'Instructional/Tech Coach',
      'Library Media Specialist',
      'School Administrator',
      'District Administrator',
      'Purchasing Manager',
      'Student',
      'Other',
    ],
    canMultiSelect: false,
    placeHolderText: 'Enter your role',
    label: 'Other role',
  },
  subjects: {
    title: 'What subject(s) do you teach?',
    options: ['English Language Arts', 'Math', 'Science', 'Social Studies', 'Other'],
    canMultiSelect: true,
    placeHolderText: 'Enter your subject(s)',
    label: 'Other subject(s)',
  },
  grades: {
    title: 'What grade(s) do you teach?',
    options: [
      'Kindergarten',
      '1st',
      '2nd',
      '3rd',
      '4th',
      '5th',
      '6th',
      '7th',
      '8th',
      '9th',
      '10th',
      '11th',
      '12th',
      '13th',
      'PD',
      'Higher Education',
      'Other',
    ],
    canMultiSelect: true,
    placeHolderText: 'Enter your grade(s)',
    label: 'Other grade(s)',
  },
  intendedUses: {
    title: 'What do you plan to use Curipod for?',
    options: [
      'Create Excitement for Reading and Writing',
      'Give Immediate Feedback on Student Writing',
      'Prepare for Testing',
      'Create Interactive Presentations Using AI',
      'Other',
    ],
    canMultiSelect: true,
    placeHolderText: 'Enter your intended use',
    label: 'Other intended use',
  },
  signUpOrigin: {
    title: 'How did you hear about Curipod?',
    options: [
      'Social Media',
      'Professional Development Session',
      'Coworker Referral',
      'Conference Presentation',
      'Other',
    ],
    canMultiSelect: false,
    placeHolderText: 'Tell us how you heard about Curipod',
    label: 'Other sign up origin',
  },
};

const StudentJoinLesson = () => {
  const metrics = useMetrics();
  const { track } = useMarketingMetrics();

  return (
    <>
      <h1 style={{ textAlign: 'center' }}>
        Jump into today&apos;s learning adventure with rocket-powered curiosity
      </h1>
      <div style={{ display: 'flex', gap: 20 }}>
        <RocketContainer>
          <CuriRocketSvg />
          <CuriRocketBigFlameSvg />
        </RocketContainer>
        <Link href={`https://${configureStore().config.game.url}`} passHref>
          <a
            style={{
              fontWeight: 500,
              fontSize: 18,
              color: theme.colors.greyLight,
              marginRight: 20,
            }}
          >
            <Button
              styles={{
                backgroundColor: theme.colors.blue,
                borderColor: theme.colors.blue,
                borderRadius: '20px',
                height: '40px',
                width: '150px',
              }}
              onClick={() => {
                track('RedirectStudentFromSignup.Curi.live.EnterPin');
                metrics.logEvent('RedirectStudentFromSignup.Curi.live.EnterPin');
              }}
            >
              Enter pin
            </Button>
          </a>
        </Link>
      </div>
    </>
  );
};

const RocketContainer = styled.div`
  position: relative;
  width: 0%;
  height: 40px;
  width: 0px;
  svg {
    height: 40px;
    width: 40px;
    position: absolute;
    right: 0px;
    top: 0px;
    transform: rotate(0deg);
    transform-origin: center;
  }

  svg:last-child {
    animation: flames 0.2s infinite linear;
  }

  @keyframes flames {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;
const RoleSelection = ({
  activeFormSection,
  setCustomRole,
  customRole,
  role,
  setRole,
  handleNext,
}: {
  activeFormSection: ActiveFormSection;
  setCustomRole: (role: string) => void;
  customRole: string | undefined;
  role: SchoolRoleType | undefined;
  setRole: (role: SchoolRoleType) => void;
  handleNext: () => void;
}) => {
  const metrics = useMetrics();
  const { track } = useMarketingMetrics();
  const shouldDescribeCustomOption = useMemo(() => {
    return role === 'Other';
  }, [role]);

  const handleSelect = (option: SchoolRoleType) => {
    track('Signup.Role.Option.Selected', {
      props: {
        selectedOption: option,
      },
    });
    metrics.logEvent('Signup.Role.Option.Selected', {
      selectedOption: option,
    });
    setRole(option);
  };

  return (
    <>
      <h1>{activeFormSection.title}</h1>
      <OptionsContainer>
        {activeFormSection.options &&
          activeFormSection.options.map((option, i) => (
            <StyledOption
              $isSelected={role === option}
              key={i}
              onClick={() => handleSelect(option as SchoolRoleType)}
            >
              {option}
            </StyledOption>
          ))}
      </OptionsContainer>
      {shouldDescribeCustomOption && (
        <div>
          <p style={{ textAlign: 'left', width: '100%', marginBottom: 0 }}>
            {activeFormSection.label}
          </p>
          <Input
            maxLength={50}
            style={{ width: 250, fontSize: 16 }}
            placeholder={activeFormSection.placeHolderText}
            onChange={e => setCustomRole(e.target.value)}
            value={customRole}
          />
        </div>
      )}
      <Button
        disabled={role === undefined}
        onClick={() => {
          track('Signup.Next.Button.Clicked', {
            props: {
              selectedRole: role,
            },
          });
          metrics.logEvent('Signup.Next.Button.Clicked', {
            selectedRole: role,
          });
          handleNext();
        }}
      >
        Next
      </Button>
    </>
  );
};

const SubjectSelection = ({
  activeFormSection,
  setCustomSubjects,
  customSubjects,
  subjects,
  setSubjects,
  handleNext,
}: {
  activeFormSection: ActiveFormSection;
  setCustomSubjects: (subjects: string) => void;
  customSubjects: string | undefined;
  subjects: SubjectType[] | undefined;
  setSubjects: (subjects: SubjectType[] | undefined) => void;
  handleNext: () => void;
}) => {
  const metrics = useMetrics();
  const { track } = useMarketingMetrics();

  const shouldDescribeCustomOption = useMemo(() => {
    return subjects?.includes('Other');
  }, [subjects]);

  const handleSelect = (option: SubjectType) => {
    track('Signup.Subject.Option.Selected', {
      props: {
        selectedOption: option,
      },
    });
    metrics.logEvent('Signup.Subject.Option.Selected', {
      selectedOption: option,
    });

    if (subjects?.includes(option)) {
      setSubjects(
        subjects.filter(s => s !== option).length === 0
          ? undefined
          : (subjects.filter(s => s !== option) as SubjectType[]),
      );
      return;
    }
    if (subjects === undefined) {
      setSubjects([option] as SubjectType[]);
    } else {
      setSubjects([...subjects, option] as SubjectType[]);
    }
  };

  return (
    <>
      <h1>{activeFormSection.title}</h1>
      <OptionsContainer>
        {activeFormSection.options &&
          activeFormSection.options.map((option, i) => (
            <StyledOption
              $isSelected={subjects?.includes(option as SubjectType) || false}
              key={i}
              onClick={() => handleSelect(option as SubjectType)}
            >
              {option}
            </StyledOption>
          ))}
      </OptionsContainer>
      {shouldDescribeCustomOption && (
        <div>
          <p style={{ textAlign: 'left', width: '100%', marginBottom: 0 }}>
            {activeFormSection.label}
          </p>
          <Input
            maxLength={50}
            style={{ width: 250, fontSize: 16 }}
            placeholder={activeFormSection.placeHolderText}
            onChange={e => setCustomSubjects(e.target.value)}
            value={customSubjects}
          />
        </div>
      )}
      <Button
        onClick={() => {
          track('Signup.Next.Button.Clicked', {
            props: {
              selectedSubjects: subjects,
            },
          });
          metrics.logEvent('Signup.Next.Button.Clicked', {
            selectedSubjects: subjects,
          });
          handleNext();
        }}
      >
        Next
      </Button>
    </>
  );
};

const GradeSelection = ({
  activeFormSection,
  setCustomGrades,
  customGrades,
  grades,
  setGrades,
  handleNext,
}: {
  activeFormSection: ActiveFormSection;
  setCustomGrades: (grades: string) => void;
  customGrades: string | undefined;
  grades: GradeType[] | undefined;
  setGrades: (grades: GradeType[] | undefined) => void;
  handleNext: () => void;
}) => {
  const metrics = useMetrics();
  const { track } = useMarketingMetrics();
  const shouldDescribeCustomOption = useMemo(() => {
    return grades?.includes('Other');
  }, [grades]);

  const handleSelect = (option: GradeType) => {
    track('Signup.Grade.Option.Selected', {
      props: {
        selectedOption: option,
      },
    });
    metrics.logEvent('Signup.Grade.Option.Selected', {
      selectedOption: option,
    });

    if (grades?.includes(option)) {
      setGrades(
        grades.filter(s => s !== option).length === 0
          ? undefined
          : grades.filter(s => s !== option),
      );
      return;
    }
    if (grades === undefined) {
      setGrades([option]);
    } else {
      setGrades([...grades, option]);
    }
  };

  return (
    <>
      <h1>{activeFormSection.title}</h1>
      <OptionsContainer>
        {activeFormSection.options &&
          activeFormSection.options.map((option, i) => (
            <StyledOption
              $isSelected={grades?.includes(option as GradeType) || false}
              key={i}
              onClick={() => handleSelect(option as GradeType)}
            >
              {option}
            </StyledOption>
          ))}
      </OptionsContainer>
      {shouldDescribeCustomOption && (
        <div>
          <p style={{ textAlign: 'left', width: '100%', marginBottom: 0 }}>
            {activeFormSection.label}
          </p>
          <Input
            maxLength={50}
            style={{ width: 250, fontSize: 16 }}
            placeholder={activeFormSection.placeHolderText}
            onChange={e => setCustomGrades(e.target.value)}
            value={customGrades}
          />
        </div>
      )}
      <Button
        onClick={() => {
          track('Signup.Next.Button.Clicked', {
            props: {
              selectedGrades: grades,
            },
          });
          metrics.logEvent('Signup.Next.Button.Clicked', {
            selectedGrades: grades,
          });
          handleNext();
        }}
      >
        Next
      </Button>
    </>
  );
};

export const SchoolSelection = ({
  role,
  setTenant,
  tenant,
  schoolName,
  schoolId,
  handleNext,
  country,
  setSchoolName,
  setSchoolId,
  couldNotFindSchool,
  setCouldNotFindSchool,
  zipCode,
  setZipCode,
}: {
  role: SchoolRoleType | undefined;
  setTenant: (tenant: { tenantId?: string; tenantName: string }) => void;
  tenant: { tenantId?: string; tenantName: string } | undefined;
  schoolName: string | undefined;
  schoolId: number | undefined;
  setSchoolName: (name: string | undefined) => void;
  setSchoolId: (id: number | undefined) => void;
  handleNext: () => void;
  country: string | undefined;
  couldNotFindSchool: boolean;
  setCouldNotFindSchool: (couldNotFind: boolean) => void;
  zipCode: string | undefined;
  setZipCode: (zipCode: string | undefined) => void;
}) => {
  const metrics = useMetrics();
  const { track } = useMarketingMetrics();

  const { data: schoolsInZip = [], isLoading: isLoadingSchoolsInZip } = useQuery({
    queryKey: ['schoolsInZip', zipCode],
    queryFn: async () => {
      if (!zipCode) return [];
      const { schools } = await backendService.instance.getSchoolsInZip(zipCode);

      return schools;
    },
  });

  useEffect(() => {
    setSchoolId(undefined);
    setSchoolName(undefined);
  }, [setSchoolId, setSchoolName]);

  useEffect(() => {
    setSchoolName(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zipCode]);

  const handleTenantNameChanged = useCallback(
    (name: string) => {
      if (tenant?.tenantName.length === 0 && name.length === 1) {
        metrics.logEvent('SignUp.Teacher.TenantName.Start');
      }
      setSchoolName(name);
      setTenant({ tenantId: undefined, tenantName: name });
    },
    [metrics, setSchoolName, setTenant, tenant?.tenantName.length],
  );

  const handleSelectionChange = useCallback(
    (schoolId: number) => {
      setSchoolId(schoolId);
      setSchoolName(
        schoolsInZip.find((s: DatabaseSchool) => s.schoolId === schoolId)?.schoolName,
      );
    },
    [setSchoolName, setSchoolId, schoolsInZip],
  );

  const searchSchools = debounce(zipCodeSearch => {
    setZipCode(zipCodeSearch);
  }, 400);

  const shouldShowSchoolSelector = useMemo(() => {
    return (
      country === 'US' &&
      role !== 'District Administrator' &&
      role !== 'Purchasing Manager' &&
      !couldNotFindSchool
    );
  }, [country, role, couldNotFindSchool]);

  const shouldShowTypeSchool = useMemo(() => {
    return (
      (country === 'US' &&
        role !== 'District Administrator' &&
        role !== 'Purchasing Manager' &&
        couldNotFindSchool) ||
      country !== 'US'
    );
  }, [country, role, couldNotFindSchool]);

  const shouldShowTypeDistrict = useMemo(() => {
    return (
      country === 'US' &&
      (role === 'District Administrator' || role === 'Purchasing Manager')
    );
  }, [country, role]);

  const dropdownOptions = schoolsInZip.map((eachSchool: DatabaseSchool) => {
    return {
      value: eachSchool.schoolId,
      label: eachSchool.schoolName,
    };
  });

  return (
    <>
      {shouldShowTypeSchool && (
        <>
          <h1>What is the name of your school?</h1>
          <TenantTypeAhead
            onSelected={setTenant}
            onPressEnter={handleNext}
            onTenantNameWritten={handleTenantNameChanged}
            label="School name"
          />
        </>
      )}
      {shouldShowSchoolSelector && (
        <>
          <h1>Let&apos;s find your school</h1>
          <SchoolSelectionContainer>
            <div style={{ position: 'relative' }}>
              <Input
                onKeyPress={event => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                maxLength={5}
                min={0}
                style={{ width: '100%' }}
                placeholder="Your school's zip code"
                onChange={e => {
                  setSchoolId(undefined);
                  searchSchools(e.target.value);
                }}
                value={zipCode}
                label="Your school's zip code"
                labelStyle={{ fontSize: 14, color: theme.colors.black }}
              />
              {isLoadingSchoolsInZip && (
                <p style={{ width: 'max-content', position: 'absolute' }}>
                  <Spin
                    indicator={
                      <LoadingOutlined
                        style={{
                          fontSize: 15,
                          color: theme.colors.green,
                          marginRight: 5,
                        }}
                        spin
                      />
                    }
                  />
                  Searching for schools<LoadingDots>...</LoadingDots>
                </p>
              )}
            </div>
            {shouldShowSchoolSelector &&
              zipCode &&
              !isLoadingSchoolsInZip &&
              schoolsInZip.length === 0 && (
                <p
                  style={{
                    width: 'fit-content',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 20,
                  }}
                >
                  Could not find any schools in this zip code. Please try again
                </p>
              )}
            {schoolsInZip.length !== 0 && !isLoadingSchoolsInZip && (
              <SelectWrapper>
                {shouldShowSchoolSelector &&
                  zipCode &&
                  !isLoadingSchoolsInZip &&
                  schoolsInZip.length > 0 && (
                    <p
                      style={{
                        width: 'fit-content',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                        marginBottom: 5,
                      }}
                    >
                      <IconWrapper width="20px" height="20px">
                        <StarStruckSvg />
                      </IconWrapper>
                      Found {schoolsInZip.length} school
                      {schoolsInZip.length > 1 ? 's' : ''}
                    </p>
                  )}
                <Select
                  placeholder="Select your school"
                  disabled={isLoadingSchoolsInZip || schoolsInZip.length === 0}
                  style={{ marginBottom: 20, width: '100%' }}
                  value={schoolId}
                  onSelect={(e: number) => {
                    handleSelectionChange(e);
                  }}
                  options={dropdownOptions}
                  loading={isLoadingSchoolsInZip}
                />
              </SelectWrapper>
            )}
          </SchoolSelectionContainer>
        </>
      )}
      {shouldShowTypeDistrict && (
        <>
          <h1>What is the name of your district?</h1>
          <TenantTypeAhead
            onSelected={setTenant}
            onPressEnter={handleNext}
            onTenantNameWritten={handleTenantNameChanged}
            label="District name"
            placeholder="e.g. Fort Worth ISD"
          />
        </>
      )}

      {schoolName && (
        <Button
          disabled={
            (!schoolName || tenant?.tenantName.length === 0) &&
            country === 'US' &&
            !couldNotFindSchool &&
            schoolId === undefined
          }
          onClick={() => {
            track('Signup.Next.Button.Clicked', {
              props: {
                selectedSchool: schoolName,
                selectedDatabaseSchool: schoolsInZip.find(
                  (s: DatabaseSchool) => s.schoolId === schoolId,
                ),
              },
            });
            metrics.logEvent('Signup.Next.Button.Clicked', {
              selectedSchool: schoolName,
              selectedDatabaseSchool: schoolsInZip.find(
                (s: DatabaseSchool) => s.schoolId === schoolId,
              ),
            });
            handleNext();
          }}
        >
          Next
        </Button>
      )}
      {zipCode && shouldShowSchoolSelector && (
        <Button
          type="linkStyle"
          onClick={() => {
            track('Signup.CouldNotFindSchool.Button.Clicked', {
              props: {
                zipCode: zipCode,
              },
            });
            metrics.logEvent('Signup.CouldNotFindSchool.Button.Clicked', {
              zipCode: zipCode,
            });
            setCouldNotFindSchool(true);
          }}
        >
          I can&apos;t find my school
        </Button>
      )}
    </>
  );
};

const SchoolSelectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: 150px;
  width: 100%;
  max-width: 400px;

  @media (max-width: 700px) {
    gap: 30px;
  }
`;

export const SelectWrapper = styled.div<{ $fluid?: boolean }>`
  cursor: pointer;
  .ant-select {
    width: 100%;
  }
  &&& .ant-select .ant-select-selector {
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    height: 42px;
    display: flex;
    align-items: center;
    font-size: 16px;
    ${props =>
      props.$fluid &&
      css`
        width: 100%;
      `}
  }
  .ant-select-selection-item {
    height: 100%;
    align-items: center;
    display: flex;
  }
  .ant-select-selection-placeholder {
    color: ${theme.colors.black};
    font-weight: normal;
  }
  ${props =>
    props.$fluid &&
    css`
      height: 100%;
    `}
`;

const CountrySelection = ({
  handleNext,
  country,
  setCountry,
}: {
  handleNext: () => void;
  country: string | undefined;
  setCountry: (country: string) => void;
}) => {
  const metrics = useMetrics();
  const { track } = useMarketingMetrics();
  return (
    <>
      <h1>In which country do you teach?</h1>
      <OptionsContainer>
        <CountrySelector
          style={{ width: '300px' }}
          onChange={country => setCountry(country)}
          value={country}
          labelStyle={{ fontSize: 14, color: theme.colors.black }}
        />
      </OptionsContainer>
      <Button
        disabled={country === undefined}
        onClick={() => {
          track('Signup.Next.Button.Clicked', {
            props: {
              selectedCountry: country,
            },
          });
          metrics.logEvent('Signup.Next.Button.Clicked', {
            selectedCountry: country,
          });
          handleNext();
        }}
      >
        Next
      </Button>
    </>
  );
};

const IntendedUseSelection = ({
  activeFormSection = activeFormSectionMapper['intendedUses'],
  setCustomIntendedUse,
  customIntendedUse,
  intendedUses,
  setIntendedUse,
  handleNext,
}: {
  activeFormSection?: ActiveFormSection;
  setCustomIntendedUse: (intendedUses: string) => void;
  customIntendedUse: string | undefined;
  intendedUses: IntendedUseType[] | undefined;
  setIntendedUse: (intendedUses: IntendedUseType[] | undefined) => void;
  handleNext: () => void;
}) => {
  const metrics = useMetrics();
  const { track } = useMarketingMetrics();
  const shouldDescribeCustomOption = useMemo(() => {
    return intendedUses?.includes('Other');
  }, [intendedUses]);

  const handleSelect = (option: IntendedUseType) => {
    track('Signup.IntendedUse.Option.Selected', {
      props: {
        selectedOption: option,
      },
    });
    metrics.logEvent('Signup.IntendedUse.Option.Selected', {
      selectedOption: option,
    });

    if (intendedUses?.includes(option)) {
      setIntendedUse(
        intendedUses.filter(s => s !== option).length === 0
          ? undefined
          : intendedUses.filter(s => s !== option),
      );
      return;
    }
    if (intendedUses === undefined) {
      setIntendedUse([option]);
    } else {
      setIntendedUse([...intendedUses, option]);
    }
  };

  return (
    <>
      <h1>{activeFormSection.title}</h1>
      <OptionsContainer>
        {activeFormSection.options &&
          activeFormSection.options.map((option, i) => (
            <StyledOption
              $isSelected={intendedUses?.includes(option as IntendedUseType) || false}
              key={i}
              onClick={() => handleSelect(option as IntendedUseType)}
            >
              {option}
            </StyledOption>
          ))}
      </OptionsContainer>
      {shouldDescribeCustomOption && (
        <div>
          <p style={{ textAlign: 'left', width: '100%', marginBottom: 0 }}>
            {activeFormSection.label}
          </p>
          <Input
            maxLength={50}
            style={{ width: 250, fontSize: 16 }}
            placeholder={activeFormSection.placeHolderText}
            onChange={e => setCustomIntendedUse(e.target.value)}
            value={customIntendedUse}
          />
        </div>
      )}
      <Button onClick={handleNext}>Next</Button>
    </>
  );
};

const SignUpOriginSelection = ({
  activeFormSection = activeFormSectionMapper['signUpOrigin'],
  setCustomSignUpOrigin,
  customSignUpOrigin,
  signUpOrigin,
  setSignUpOrigin,
  handleNext,
}: {
  activeFormSection?: ActiveFormSection;
  setCustomSignUpOrigin: (signUpOrigin: string) => void;
  customSignUpOrigin: string | undefined;
  signUpOrigin: SignUpOriginType | undefined;
  setSignUpOrigin: (signUpOrigin: SignUpOriginType | undefined) => void;
  handleNext: () => void;
}) => {
  const metrics = useMetrics();
  const { track } = useMarketingMetrics();
  const shouldDescribeCustomOption = useMemo(() => {
    return signUpOrigin === 'Other';
  }, [signUpOrigin]);

  const handleSelect = (option: SignUpOriginType) => {
    track('Signup.SignUpOrigin.Option.Selected', {
      props: {
        selectedOption: option,
      },
    });
    metrics.logEvent('Signup.SignUpOrigin.Option.Selected', {
      selectedOption: option,
    });
    setSignUpOrigin(option);
  };

  return (
    <>
      <h1>{activeFormSection.title}</h1>
      <OptionsContainer>
        {activeFormSection.options &&
          activeFormSection.options.map((option, i) => (
            <StyledOption
              $isSelected={signUpOrigin === option}
              key={i}
              onClick={() => handleSelect(option as SignUpOriginType)}
            >
              {option}
            </StyledOption>
          ))}
      </OptionsContainer>
      {shouldDescribeCustomOption && (
        <div>
          <p style={{ textAlign: 'left', width: '100%', marginBottom: 0 }}>
            {activeFormSection.label}
          </p>
          <Input
            maxLength={50}
            style={{ width: 250, fontSize: 16 }}
            placeholder={activeFormSection.placeHolderText}
            onChange={e => setCustomSignUpOrigin(e.target.value)}
            value={customSignUpOrigin}
          />
        </div>
      )}
      <Button onClick={handleNext}>Next</Button>
    </>
  );
};

const CreateAccountForm = () => {
  const metrics = useMetrics();
  const { track } = useMarketingMetrics();
  const [role, setRole] = useState<SchoolRoleType>();
  const [customRole, setCustomRole] = useState<string>();
  const [country, setCountry] = useState<string>();
  const [schoolName, setSchoolName] = useState<string>();
  const [schoolId, setSchoolId] = useState<number>();
  const [subjects, setSubjects] = useState<SubjectType[] | undefined>();
  const [intendedUses, setIntendedUse] = useState<IntendedUseType[] | undefined>();
  const [signUpOrigin, setSignUpOrigin] = useState<SignUpOriginType>();
  const [grades, setGrades] = useState<GradeType[] | undefined>();
  const [customSubjects, setCustomSubjects] = useState<string>();
  const [customGrades, setCustomGrades] = useState<string>();
  const [currentForm, setCurrentForm] = useState<CurrentForm>('role');
  const [zipCode, setZipCode] = useState<string>();
  const [tenant, setTenant] = useState<{ tenantId?: string; tenantName: string }>();
  const [couldNotFindSchool, setCouldNotFindSchool] = useState(false);
  const [customIntendedUse, setCustomIntendedUse] = useState<string>();
  const [customSignUpOrigin, setCustomSignUpOrigin] = useState<string>();
  //determine whether the current form is the last form before submitting
  const isFinalForm = useMemo(() => {
    if (role === 'Student') {
      return currentForm === 'studentJoinForm';
    } else {
      return currentForm === 'signUpOrigin';
    }
  }, [currentForm, role]);

  const selectNextCurrentForm = useCallback(() => {
    switch (currentForm) {
      case 'role':
        if (role === 'Student') {
          setCurrentForm('studentJoinForm');
        } else {
          setCurrentForm('country');
        }
        break;
      case 'country':
        setCurrentForm('selectSchool');
        break;
      case 'selectSchool':
        if (role === 'Teacher') {
          setCurrentForm('subjects');
        } else {
          setCurrentForm('signUpOrigin');
        }
        break;
      case 'subjects':
        setCurrentForm('grades');
        break;
      case 'grades':
        setCurrentForm('intendedUses');
        break;
      case 'intendedUses':
        setCurrentForm('signUpOrigin');
        break;
      // signUpOrigin is always the last form
      default:
        break;
    }
  }, [currentForm, role]);

  const selectPreviousForm = useCallback(
    (currentForm: CurrentForm) => {
      switch (currentForm) {
        case 'country':
          setCurrentForm('role');
          break;
        case 'studentJoinForm':
          setCurrentForm('role');
          break;
        case 'selectSchool':
          setCouldNotFindSchool(false);
          setCurrentForm('country');
          setSchoolName(undefined);
          setTenant(undefined);
          break;
        case 'subjects':
          setCurrentForm('selectSchool');
          break;
        case 'grades':
          setCurrentForm('subjects');
          break;
        case 'intendedUses':
          setCurrentForm('grades');
          break;
        case 'signUpOrigin':
          if (role === 'Teacher') {
            setCurrentForm('intendedUses');
          } else {
            setCurrentForm('selectSchool');
          }
          break;
        default:
          break;
      }
    },
    [role],
  );

  const { signUp } = useSignUp();
  const router = useRouter();
  const callback = router.query.callback as string | undefined;
  const handleGoBack = () => {
    selectPreviousForm(currentForm);
    track('Signup.Back.Button.Clicked', {
      props: {
        currentSignupPage: currentForm,
        from: 'signup',
      },
    });
    metrics.logEvent('Signup.Back.Button.Clicked', {
      currentSignupPage: currentForm,
      from: 'signup',
    });
  };
  const canSkipCurrentForm = useMemo(() => {
    return (
      currentForm === 'grades' ||
      currentForm === 'subjects' ||
      currentForm === 'intendedUses' ||
      currentForm === 'signUpOrigin'
    );
  }, [currentForm]);

  const handleGoForward = (isSkip?: boolean) => {
    if (isSkip && currentForm === 'signUpOrigin') {
      signUp({
        callbackUrl: callback ? decodeURIComponent(callback) : '/home',
        authParams: {
          accountType: 'teacher',
          ui_locales: 'en',
          tenantName: '',
          schoolRole: role ? role : '',
          subjects: subjects ? JSON.stringify(subjects) : '',
          grades: grades ? JSON.stringify(grades) : '',
          otherRole: customRole ? customRole : '',
          otherSubjects: customSubjects ? customSubjects : '',
          otherGrades: customGrades ? customGrades : '',
          v2Properties: JSON.stringify({
            ...(schoolId ? { schoolId: schoolId } : {}),
            country: country ? country : '',
            intendedUses: intendedUses ? JSON.stringify(intendedUses) : '',
            signUpOrigin: signUpOrigin ? signUpOrigin : '',
            otherIntendedUse: customIntendedUse || '',
            otherSignUpOrigin: customSignUpOrigin || '',
          }),
        },
      });
    }
    selectNextCurrentForm();
    if (isSkip) {
      track(`Signup.Click.Skip`, {
        props: {
          currentSignupPage: currentForm,
        },
      });
      metrics.logEvent('Signup.Click.Skip', {
        currentSignupPage: currentForm,
      });
    } else {
      track(`Signup.Click.Next`, {
        props: {
          currentSignupPage: currentForm,
          schoolRole: role ? role : '',
          subjects: subjects ? JSON.stringify(subjects) : '',
          grades: grades ? JSON.stringify(grades) : '',
          otherRole: customRole ? customRole : '',
          otherSubjects: customSubjects ? customSubjects : '',
          otherGrades: customGrades ? customGrades : '',
          country: country ? country : '',
          schoolName: schoolName ? schoolName : '',
          intendedUses: intendedUses ? JSON.stringify(intendedUses) : '',
          signUpOrigin: signUpOrigin ? JSON.stringify(signUpOrigin) : '',
          otherIntendedUse: customIntendedUse || '',
          otherSignUpOrigin: customSignUpOrigin || '',
        },
      });
      metrics.logEvent('Signup.Click.Next', {
        currentSignupPage: currentForm,
        schoolRole: role ? role : '',
        subjects: subjects ? JSON.stringify(subjects) : '',
        grades: grades ? JSON.stringify(grades) : '',
        otherRole: customRole ? customRole : '',
        otherSubjects: customSubjects ? customSubjects : '',
        otherGrades: customGrades ? customGrades : '',
        country: country ? country : '',
        schoolName: schoolName ? schoolName : '',
        intendedUses: intendedUses ? JSON.stringify(intendedUses) : '',
        signUpOrigin: signUpOrigin ? JSON.stringify(signUpOrigin) : '',
        otherIntendedUse: customIntendedUse || '',
        otherSignUpOrigin: customSignUpOrigin || '',
      });
    }
  };

  const allOptionsSelected = useMemo(() => {
    switch (currentForm) {
      case 'role':
        return role !== undefined;
      case 'subjects':
        if (!subjects) return false;
        return (
          subjects.length >= activeFormSectionMapper[currentForm]?.options.length - 1
        );
      case 'grades':
        if (!grades) return false;
        return grades.length >= activeFormSectionMapper[currentForm]?.options.length - 1;
      case 'intendedUses':
        return intendedUses !== undefined;
      case 'signUpOrigin':
        return signUpOrigin !== undefined;
      default:
        return false;
    }
  }, [currentForm, role, subjects, grades, intendedUses, signUpOrigin]);

  const handleSelectAll = useCallback(() => {
    switch (currentForm) {
      case 'subjects':
        setSubjects(
          activeFormSectionMapper[currentForm]?.options.filter(
            o => o !== 'Other',
          ) as SubjectType[],
        );
        break;
      case 'grades':
        setGrades(
          activeFormSectionMapper[currentForm]?.options.filter(
            o => o !== 'Other',
          ) as GradeType[],
        );
        break;
      default:
        break;
    }
    track(`Signup.SelectAll`, {
      props: {
        currentSignupPage: currentForm,
      },
    });
    metrics.logEvent('Signup.SelectAll', {
      currentSignupPage: currentForm,
    });
  }, [currentForm, track, metrics]);

  const handleDeselectAll = useCallback(() => {
    switch (currentForm) {
      case 'subjects':
        setSubjects(undefined);
        break;
      case 'grades':
        setGrades(undefined);
        break;
      case 'intendedUses':
        setIntendedUse(undefined);
        break;
      case 'signUpOrigin':
        setSignUpOrigin(undefined);
        break;
      default:
        break;
    }
    track(`Signup.DeselectAll`, {
      props: {
        currentSignupPage: currentForm,
      },
    });
    metrics.logEvent('Signup.DeselectAll', {
      currentSignupPage: currentForm,
    });
  }, [currentForm, track, metrics]);

  const userInfo = {
    role: role,
    subjects: JSON.stringify(subjects),
    grades: JSON.stringify(grades),
    otherRole: customRole,
    otherSubjects: customSubjects,
    otherGrades: customGrades,
    contry: country,
    schoolName: schoolName,
    intendedUses: intendedUses,
    signUpOrigin: signUpOrigin,
  };

  const handleContinue = () => {
    metrics.logEvent('SignUp.Teacher.TenantName.Submit', {
      schoolRole: userInfo.role,
      subjects: userInfo.subjects,
      grades: userInfo.grades,
      otherRole: userInfo.otherRole,
      otherSubjects: userInfo.otherSubjects,
      otherGrades: userInfo.otherGrades,
      contry: userInfo.contry,
      schoolName: userInfo.schoolName,
      intendedUses: userInfo.intendedUses,
      signUpOrigin: userInfo.signUpOrigin,
      otherIntendedUse: customIntendedUse || '',
      otherSignUpOrigin: customSignUpOrigin || '',
    });
    track('SignUp.TenantName.Submit', {
      props: {
        event: 'submit',
        from: 'signup',
        schoolRole: userInfo.role,
        subjects: userInfo.subjects,
        grades: userInfo.grades,
        otherRole: userInfo.otherRole,
        otherSubjects: userInfo.otherSubjects,
        otherGrades: userInfo.otherGrades,
        contry: userInfo.contry,
        schoolName: userInfo.schoolName,
        intendedUses: userInfo.intendedUses ? JSON.stringify(userInfo.intendedUses) : '',
        signUpOrigin: userInfo.signUpOrigin ? JSON.stringify(userInfo.signUpOrigin) : '',
        otherIntendedUse: customIntendedUse || '',
        otherSignUpOrigin: customSignUpOrigin || '',
      },
    });
    signUp({
      callbackUrl: callback ? decodeURIComponent(callback) : '/home',
      authParams: {
        accountType: 'teacher',
        ui_locales: 'en',
        tenantName: tenant?.tenantName ? tenant.tenantName : '',
        schoolRole: userInfo.role ? userInfo.role : '',
        subjects: userInfo.subjects ? JSON.stringify(userInfo.subjects) : '',
        grades: userInfo.grades ? JSON.stringify(userInfo.grades) : '',
        otherRole: userInfo.otherRole ? userInfo.otherRole : '',
        otherSubjects: userInfo.otherSubjects ? userInfo.otherSubjects : '',
        otherGrades: userInfo.otherGrades ? userInfo.otherGrades : '',
        v2Properties: JSON.stringify({
          ...(schoolId ? { schoolId: schoolId } : {}),
          country: userInfo.contry ? userInfo.contry : '',
          intendedUses: userInfo.intendedUses
            ? JSON.stringify(userInfo.intendedUses)
            : '',
          signUpOrigin: userInfo.signUpOrigin ? userInfo.signUpOrigin : '',
          otherIntendedUse: customIntendedUse || '',
          otherSignUpOrigin: customSignUpOrigin || '',
        }),
      },
    });
  };

  const formNumber = useMemo(() => {
    switch (currentForm) {
      case 'role':
        return 0;
      case 'country':
        return 1;
      case 'selectSchool':
        return 2;
      case 'subjects':
        return 3;
      case 'grades':
        return 4;
      case 'intendedUses':
        return 5;
      case 'signUpOrigin':
        return 6;
      default:
        return 0;
    }
  }, [currentForm]);

  return (
    <>
      <InfoBar
        handleGoBack={handleGoBack}
        showProgress={role === 'Teacher' && currentForm !== 'role'}
        canGoBack={currentForm !== 'role'}
        canSkipCurrentForm={canSkipCurrentForm}
        handleGoForward={handleGoForward}
        formNumber={formNumber}
        currentForm={currentForm}
      />

      <SignupFlowContainer>
        {currentForm === 'role' && (
          <RoleSelection
            activeFormSection={activeFormSectionMapper[currentForm]}
            customRole={customRole}
            setCustomRole={setCustomRole}
            role={role}
            setRole={setRole}
            handleNext={() => {
              isFinalForm ? handleContinue() : handleGoForward();
            }}
          />
        )}
        {currentForm === 'country' && (
          <CountrySelection
            handleNext={() => {
              handleGoForward();
            }}
            country={country}
            setCountry={setCountry}
          />
        )}
        {currentForm === 'selectSchool' && (
          <SchoolSelection
            role={role}
            couldNotFindSchool={couldNotFindSchool}
            setCouldNotFindSchool={setCouldNotFindSchool}
            setTenant={setTenant}
            tenant={tenant}
            handleNext={() => {
              isFinalForm ? handleContinue() : handleGoForward();
            }}
            schoolName={schoolName}
            schoolId={schoolId}
            setSchoolName={setSchoolName}
            setSchoolId={setSchoolId}
            country={country}
            zipCode={zipCode}
            setZipCode={setZipCode}
          />
        )}
        {currentForm === 'subjects' && (
          <SubjectSelection
            activeFormSection={activeFormSectionMapper[currentForm]}
            customSubjects={customSubjects}
            setCustomSubjects={setCustomSubjects}
            subjects={subjects}
            setSubjects={setSubjects}
            handleNext={() => {
              handleGoForward();
            }}
          />
        )}
        {currentForm === 'grades' && (
          <GradeSelection
            activeFormSection={activeFormSectionMapper[currentForm]}
            customGrades={customGrades}
            setCustomGrades={setCustomGrades}
            grades={grades}
            setGrades={setGrades}
            handleNext={() => {
              isFinalForm ? handleContinue() : handleGoForward();
            }}
          />
        )}
        {currentForm === 'intendedUses' && (
          <IntendedUseSelection
            activeFormSection={activeFormSectionMapper[currentForm]}
            customIntendedUse={customIntendedUse}
            setCustomIntendedUse={setCustomIntendedUse}
            intendedUses={intendedUses}
            setIntendedUse={setIntendedUse}
            handleNext={() => {
              isFinalForm ? handleContinue() : handleGoForward();
            }}
          />
        )}
        {currentForm === 'signUpOrigin' && (
          <SignUpOriginSelection
            activeFormSection={activeFormSectionMapper[currentForm]}
            customSignUpOrigin={customSignUpOrigin}
            setCustomSignUpOrigin={setCustomSignUpOrigin}
            signUpOrigin={signUpOrigin}
            setSignUpOrigin={setSignUpOrigin}
            handleNext={() => {
              isFinalForm ? handleContinue() : handleGoForward();
            }}
          />
        )}

        {currentForm === 'studentJoinForm' && <StudentJoinLesson />}
        {currentForm !== 'selectSchool' &&
          currentForm !== 'country' &&
          currentForm !== 'studentJoinForm' &&
          activeFormSectionMapper[currentForm].canMultiSelect && (
            <Button
              type="ghost"
              styles={{
                border: 'none',
                color: theme.colors.primary,
                background: theme.colors.white,
                padding: 0,
                boxShadow: 'none',
              }}
              onClick={allOptionsSelected ? handleDeselectAll : handleSelectAll}
            >
              {allOptionsSelected ? 'Deselect All' : 'Select All'}
            </Button>
          )}

        <span style={{ fontWeight: 500 }}>Created with 🧡 from 🇳🇴</span>
      </SignupFlowContainer>
    </>
  );
};

const SignupFlowContainer = styled.div`
  width: 100%;
  background-color: ${theme.colors.white};
  display: flex;
  gap: 30px;
  flex-direction: column;
  align-items: center;
  box-shadow: ${theme.effects.floatingCreatorElementShadow};
  border-radius: 15px;
  padding: 20px 20px 30px 20px;
`;

const OptionsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px 15px;
  align-items: center;
  justify-content: center;
`;

const StyledOption = styled.div<{ $isSelected: boolean }>`
  padding: 10px 20px;
  border-radius: 30px;
  background-color: ${theme.colors.white};
  color: ${theme.colors.black};
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  border: 1px solid ${theme.colors.black};
  cursor: pointer;
  transition: 0.2s ease-in-out;

  &:hover {
    background-color: ${theme.colors.grey};
    color: ${({ $isSelected }) =>
      $isSelected ? theme.colors.green : theme.colors.black};
    border: 1px solid ${theme.colors.white};
  }

  ${({ $isSelected }) =>
    $isSelected &&
    css`
      color: ${theme.colors.green};
      border: 1px solid ${theme.colors.green};
    `}
`;

const Login = () => {
  const metrics = useMetrics();
  const { signIn } = useSignIn();
  const router = useRouter();
  const { t } = useTranslation('Host');
  const { track } = useMarketingMetrics();
  const callback = router.query.callback as string | undefined;
  const isLargerThan500 = useBreakPoint(500);
  const handleLogin = () => {
    signIn({
      callbackUrl: callback ? decodeURIComponent(callback) : '/',
    });
    track('SignUp.Topbar.Login');
    metrics.logEvent('SignUp.Topbar.Login');
  };
  return (
    <LoginText onClick={handleLogin}>
      {isLargerThan500 ? t('Already have an account? Log in') : t('Log in')}
    </LoginText>
  );
};

const LoginText = styled.span`
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const InfoBar = ({
  handleGoBack,
  canGoBack,
  canSkipCurrentForm,
  handleGoForward,
  currentForm,
  showProgress,
  formNumber,
}: {
  handleGoBack: () => void;
  canGoBack: boolean;
  canSkipCurrentForm: boolean;
  handleGoForward: (isSkip?: boolean) => void;
  currentForm: CurrentForm;
  showProgress: boolean;
  formNumber: number;
}) => {
  const metrics = useMetrics();
  const { track } = useMarketingMetrics();
  const { t } = useTranslation('Host');
  const isLargerThan500 = useBreakPoint(500);
  return (
    <InfoBarContainer>
      {canGoBack && (
        <Button
          styles={{
            border: 'none',
            position: 'absolute',
            left: 10,
            top: 10,
            background: 'none',
            boxShadow: 'none',
          }}
          icon={<ChevronLeft color={theme.colors.black} />}
          onClick={() => {
            track('Signup.Back.Button.Clicked', {
              props: {
                currentSignupPage: currentForm,
              },
            });
            metrics.logEvent('Signup.Back.Button.Clicked', {
              currentSignupPage: currentForm,
            });
            handleGoBack();
          }}
          type="ghost"
        >
          {isLargerThan500 ? t('Go back') : ' '}
        </Button>
      )}
      <ProgressBarContainer $showProgress={showProgress}>
        <h2 style={{ gridColumn: '1 / -1', justifySelf: 'center', marginBottom: 0 }}>
          Create Account
        </h2>
        {showProgress && (
          <>
            <ProgressBar $isComplete={formNumber >= 0} />
            <ProgressBar $isComplete={formNumber >= 1} />
            <ProgressBar $isComplete={formNumber >= 2} />
            <ProgressBar $isComplete={formNumber >= 3} />
            <ProgressBar $isComplete={formNumber >= 4} />
            <ProgressBar $isComplete={formNumber >= 5} />
            <ProgressBar $isComplete={formNumber >= 6} />
          </>
        )}
      </ProgressBarContainer>
      {canSkipCurrentForm && (
        <Button
          styles={{
            border: 'none',
            position: 'absolute',
            right: 10,
            top: 10,
            flexDirection: 'row-reverse',
            background: 'none',
            boxShadow: 'none',
          }}
          icon={<ChevronRight color={theme.colors.black} />}
          onClick={() => handleGoForward(true)}
          type="ghost"
        >
          {isLargerThan500 ? t('Skip') : ' '}
        </Button>
      )}
    </InfoBarContainer>
  );
};

const InfoBarContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ProgressBarContainer = styled.div<{ $showProgress: boolean }>`
  width: 280px;
  display: grid;
  grid-template-rows: ${({ $showProgress }) => ($showProgress ? '3fr 2fr' : '1fr')};
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 5px;
  margin: ${({ $showProgress }) => ($showProgress ? '0px' : '20px 0px')};
  @media (max-width: 500px) {
    width: 200px;
  }
`;
const ProgressBar = styled.div<{ $isComplete: boolean }>`
  width: 100%;
  height: 5px;
  border-radius: 5px;
  background: ${theme.colors.orange} !important;
  opacity: ${({ $isComplete }) => ($isComplete ? 1 : 0.3)};
`;

const Signup = () => {
  return (
    <SignupPageContainer>
      <SignupTopbar />
      <PageContentContainer hasTopBar={true} style={{ padding: 0 }}>
        <Container>
          <CreateAccountForm />
        </Container>
      </PageContentContainer>
    </SignupPageContainer>
  );
};

const SignupPageContainer = styled.div`
  height: 100%;
  width: 100%;

  @media (max-width: 550px) {
    h1 {
      font-size: 25px;
      margin-bottom: 0px;
    }
  }
`;
const Container = styled.div`
  height: 100%;
  display: flex;
  max-width: 1000px;
  margin: 0px auto;
  padding: 0px 20px;
  align-items: center;
  flex-direction: column;
  position: relative;
`;

export const getServerSideProps: GetServerSideProps = async context => {
  context.res.setHeader(
    'Cache-Control',
    'public, s-maxage=300, stale-while-revalidate=3600',
  );

  const session = await getSession({ req: context.req });
  if (!session) {
    return {
      props: {
        ...(await detectAndBestEffortSetLanguage(context)),
      },
    };
  } else {
    const queryClient = new QueryClient();
    return {
      props: {
        ...(await provisionFlags(context, queryClient, session)),
        dehydratedState: dehydrateReactQueryPrefetchedInfiniteQuery(queryClient),
        ...(await detectAndBestEffortSetLanguage(context)),
        session,
      },
    };
  }
};

export default Signup;
