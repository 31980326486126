import { useMutation } from '@tanstack/react-query';

import backendService from '../services/backendService';
import useRouterMatch from './useRouterMatch';

const useSendSlackMessage = () => {
  //This sends a slack message to the #in-app-feedback channel
  //We can use it to notify the team of various events we want to be aware of
  const { tenantId } = useRouterMatch();
  const { mutate: submit, isLoading, isSuccess } = useMutation<
    void,
    string,
    { message: string; accepted?: boolean }
  >(({ message, accepted }: { message: string; accepted?: boolean }) =>
    backendService.instance.feedback.submit(message, tenantId, accepted),
  );

  return {
    submit,
    isLoading,
    isSuccess,
  };
};

export default useSendSlackMessage;
