import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import BackendService from '../services/backendService';
import { TenantUserRole } from '../services/backendService/backendService';
import useRouterMatch from './useRouterMatch';
export const usersCacheKey = 'workspaceUsers';

function useTenantUsers() {
  const client = useQueryClient();
  const { tenantId } = useRouterMatch();
  const { data, isFetching, error } = useQuery(
    [usersCacheKey, tenantId],
    () => {
      if (!tenantId) return Promise.resolve(undefined);
      return BackendService.instance.tenant.listUsers(tenantId);
    },
    {
      enabled: Boolean(tenantId),
      staleTime: 1000 * 60 * 15,
    },
  );

  const { mutate: editUserProfile, isSuccess: isEditUserProfileSuccess } = useMutation(
    (config: {
      data: { role: TenantUserRole; userId: string };
      onSuccess?: () => void;
    }) => {
      return BackendService.instance.tenant.editUserProfile(config.data);
    },
    {
      onSettled: () => {
        client.invalidateQueries([usersCacheKey]);
      },

      onSuccess: (data, { onSuccess }) => {
        if (onSuccess) {
          onSuccess();
        }
      },
    },
  );
  const { mutate: removeUser, isLoading: isRemovingUser } = useMutation(
    (config: { uid: string; onSuccess?: () => void }) => {
      return BackendService.instance.tenant.removeUser(config.uid);
    },
    {
      onSettled: () => {
        client.invalidateQueries([usersCacheKey]);
      },

      onSuccess: (data, { onSuccess }) => {
        if (onSuccess) {
          onSuccess();
        }
      },
    },
  );
  return {
    users: data?.users,
    usersById: data?.byId || {},
    error,
    removeUser,
    isRemovingUser,
    editUserProfile,
    isEditUserProfileSuccess,
    isLoading: isFetching,
  };
}

export default useTenantUsers;
