import { Collapse } from 'antd';
import { motion, useAnimation } from 'framer-motion';
import Link from 'next/link';
import router from 'next/router';
import { useSession } from 'next-auth/react';
import { useEffect, useRef, useState } from 'react';
import React from 'react';
import { useIntercom } from 'react-use-intercom';
import styled from 'styled-components';

import { CheckSvg } from '../../../assets/icons';
import DownloadSvg from '../../../assets/icons/DownloadSvg';
import MinusSvg from '../../../assets/icons/MinusSvg';
import theme from '../../../assets/theme';
import { LicenseCoarseLevels, LicenseFineLevels } from '../../../hooks/useAccessBadge';
import usePaywallPrompt from '../../../hooks/usePaywallPrompt';
import { useMarketingMetrics } from '../../../providers/PlausibleAnalyticsProvider';
import { Button } from '../../_atoms/Button';
import { IconWrapper } from '../../_atoms/IconWrapper';
import { NextPageWithLayout } from '../../layouts';
import { featureCompareTableData } from './utils';

export const FAQSection = () => {
  const { track } = useMarketingMetrics();
  const { Panel } = Collapse;
  const items: {
    key: string;
    label: string;
    content: JSX.Element;
  }[] = [
    {
      key: '1',
      label: 'How do I buy Curipod school site license?',
      content: (
        <p>
          Buying Curipod is easy! Fill out the{' '}
          <Link href={'/quotes'}>
            <a
              onClick={() => {
                track('PricingPage.faq.schoolLicenceForm.clicked');
              }}
              target="__blank"
              rel="noopener noreferrer"
            >
              quote request form{' '}
            </a>
          </Link>
          . You&apos;ll hear back from us ASAP with a quote and licensing agreement. After
          the agreement is signed, we&apos;ll send an invoice and setup your school&apos;s
          Enterprise account.
          <br />
          <br />
          Curipod site licenses can be purchased with credit card (we&apos;ll send you a
          link) or by mailing a check.
        </p>
      ),
    },
    {
      key: '2',
      label: 'Can I buy a license for X number of teachers?',
      content: (
        <p>
          No. Curipod is only available through either School site License or District
          License. We keep our prices as affordable as possible. We do offer discounts for
          small schools (less than 200 students) and work with schools with budget
          constraints.
          <br />
          <br />
          We don&apos;t want to price to be a barrier for accessing Curipod and bringing
          curiosity to your classroom. If you would like more information about our
          pricing options and making Curipod accessible for you, please reach out to{' '}
          <a
            onClick={() => {
              track('PricingPage.faq.emailSmallSchools.clicked');
            }}
            style={{ color: theme.colors.blue }}
            href="mailto:amanda.aitkens@curipod.com"
          >
            amanda.aitkens@curipod.com
          </a>
          . We&apos;re happy to work with you to find a solution.
        </p>
      ),
    },
    {
      key: '3',
      label: 'How do I get a quote for my school or district?',
      content: (
        <p>
          Fill out{' '}
          <Link href={'/quotes'}>
            <a
              onClick={() => {
                track('PricingPage.faq.quoteSchoolOrDistrict.clicked');
              }}
              target="__blank"
              rel="noopener noreferrer"
            >
              this form{' '}
            </a>
          </Link>{' '}
          and we will send you a quote ASAP.
        </p>
      ),
    },
    {
      key: '4',
      label: 'How can I pay?',
      content: (
        <p>
          We accept online payment - we will send you a link if that&apos;s your preferred
          method.
          <br />
          <br />
          Curipod can also be purchased via check with an invoice.
        </p>
      ),
    },
    {
      key: '5',
      label: 'Do you have a W-9?',
      content: (
        <p>
          YES!
          <br />
          <br />
          You can also access Curipod&apos;s W-9 here:
          <br />
          <a
            style={{ textDecoration: 'none' }}
            href="/Form W-9 Curipod Inc.pdf"
            target="__blank"
            rel="noopener noreferrer"
            onClick={() => {
              track('PricingPage.faq.W9Download.clicked');
            }}
          >
            <Button
              styles={{ border: 'none', boxShadow: 'none' }}
              type="ghost"
              icon={<DownloadSvg />}
            >
              Form W-9 Curipod.pdf
            </Button>
          </a>
          <br />
          Email{' '}
          <a
            onClick={() => {
              track('PricingPage.faq.emailW8BENQuestions.clicked');
            }}
            style={{ color: theme.colors.blue }}
            href="mailto:amanda.aitkens@curipod.com"
          >
            amanda.aitkens@curipod.com
          </a>{' '}
          if you have any questions about this.
        </p>
      ),
    },
    {
      key: '6',
      label: 'Do you offer any discounts or other incentives?',
      content: (
        <p>
          We want to see students engaged through Curipod in classrooms all around the
          world, so we work to keep our pricing affordable and transparent.
          <br />
          <ul>
            <li>We have one affordable, fixed price per school site.</li>
            <li>
              We don&apos;t have expensive sales people so we keep the price down (and you
              don&apos;t have to haggle on the price).
            </li>
          </ul>
          <br />
          However, we recognize that there are budgetary constraints. We are happy to work
          with you to make Curipod affordable for your school or district. We offer the
          following options:
          <ul>
            <li>
              <strong>Small School Discount</strong>: If your school is less than 200
              students, we have a small school price for you. Email{' '}
              <a
                onClick={() => {
                  track('PricingPage.faq.emailDiscountQuestions.clicked');
                }}
                style={{ color: theme.colors.blue }}
                href="mailto:amanda.aitkens@curipod.com"
              >
                amanda.aitkens@curipod.com
              </a>{' '}
              for a custom quote.{' '}
            </li>
            <li>
              <strong>Financial Aid Discounts</strong>: We don&apos;t want money to be a
              barrier to accessing Curipod! Please contact us if your school cannot afford
              Curipod. We are happy to connect with your administration so that we can
              find a way for you to access Curipod premium features. Email{' '}
              <a
                onClick={() => {
                  track('PricingPage.faq.emailDiscountQuestions.clicked');
                }}
                style={{ color: theme.colors.blue }}
                href="mailto:amanda.aitkens@curipod.com"
              >
                amanda.aitkens@curipod.com
              </a>{' '}
              for support.
            </li>
          </ul>
        </p>
      ),
    },
    {
      key: '7',
      label: 'How do you process data?',
      content: (
        <p>
          We have a page dedicated this, which you can{' '}
          <Link href={'/c/state-and-district-privacy-agreements'}>
            <a
              onClick={() => {
                track('PricingPage.faq.stateAndDistrictPrivacyAgreements.clicked');
              }}
            >
              check out here
            </a>
          </Link>
          . Data privacy is something we take very seriously.
          <ul>
            <li>We are FERPA and COPPA compliant.</li>
            <li>
              Only teachers are allowed to have Curipod accounts, students join with a pin
              code.
            </li>
            <li>
              We sign data privacy agreements with schools and districts. Fill out the{' '}
              <Link href={'https://share.hsforms.com/1Nwgx-5qBT_euEVjxG-eztw4evga'}>
                <a
                  onClick={() => {
                    track('PricingPage.faq.signDataPrivacy.clicked');
                  }}
                  target="__blank"
                  rel="noopener noreferrer"
                >
                  form here
                </a>
              </Link>{' '}
              to request a signed DPA for your school.
            </li>
          </ul>
        </p>
      ),
    },
    {
      key: '8',
      label: 'Can you sign our data privacy agreement?',
      content: (
        <p>
          Absolutely! We have a high demand for data privacy agreements and do our best to
          process them in a timely order. As a result, we give priority to those
          interested in purchasing a Curipod school or district license.
          <br />
          <br />
          Please fill out the{' '}
          <Link href={'https://share.hsforms.com/1Nwgx-5qBT_euEVjxG-eztw4evga'}>
            <a
              onClick={() => {
                track('PricingPage.faq.signDataPrivacy.clicked');
              }}
              target="__blank"
              rel="noopener noreferrer"
            >
              form here
            </a>
          </Link>
          . You can email{' '}
          <a
            onClick={() => {
              track('PricingPage.faq.emailDataPrivacyQuestions.clicked');
            }}
            style={{ color: theme.colors.blue }}
            href="mailto:amanda.aitkens@curipod.com"
          >
            amanda.aitkens@curipod.com
          </a>{' '}
          with any questions.
        </p>
      ),
    },
    {
      key: '9',
      label: 'Other questions?',
      content: (
        <p>
          Send an email to{' '}
          <a
            onClick={() => {
              track('PricingPage.faq.emailOtherQuestions.clicked');
            }}
            style={{ color: theme.colors.blue }}
            href="mailto:amanda.aitkens@curipod.com"
          >
            amanda.aitkens@curipod.com
          </a>
          . We&apos;re happy to answer any questions you might have!
        </p>
      ),
    },
  ];

  return (
    <GreenWrapper $isLandingPage={true}>
      <CenteredSection>
        <Title style={{ color: theme.colors.white }}>Frequently asked questions</Title>
        <FAQCollapse accordion>
          {items.map(item => (
            <Panel header={item.label} key={item.key}>
              {item.content}
            </Panel>
          ))}
        </FAQCollapse>
      </CenteredSection>
    </GreenWrapper>
  );
};

const FAQCollapse = styled(Collapse)`
  font-size: 18px;
  font-family: 'Nunito';
  border: none;
  padding: 20px;
  background: ${theme.colors.primary};

  p {
    margin: 0;
  }

  a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
  svg {
    width: 18px;
    height: 18px;
  }
  .ant-collapse-item .ant-collapse-header {
    color: ${theme.colors.white};
    font-weight: bold;
    font-size: 20px;
  }

  .ant-collapse-item {
    border: none;
    border-bottom: 1px solid #ffffff90;
    border-radius: 0px !important;
  }
  .ant-collapse-item.ant-collapse-item.ant-collapse-item-active {
    border-bottom: none;
  }

  .ant-collapse-content {
    border: none;
    border-left: 4px solid ${theme.colors.orange};
    border-radius: 10px;
  }

  .ant-collapse-item:last-child > .ant-collapse-content {
    border-radius: 10px;
  }
`;

const inspiration = [
  {
    title: 'From New Teachers-  Saving Time:',
    quote:
      'I am a new teacher and am having to build TWO curriculums for my school. Curipod has helped me save so much time in getting our daily activities together so THANK YOU!!!',
    name: 'Teacher at Paragon Prep',
  },
  {
    title: 'The Students Experience:',
    quote:
      "My experience with Curipod was pretty fun. It was a fun escape from boring work. When we could be doing boring SEL, Ms. Garcia would hop on Curipod. My face would light up. Curipod helped me improve my writing as well. I really love that Curipod has a drawing option. I can express my art and show off to my classmates. My favorite part is that it doesn't show your names.",
    name: '6th Grade Student from Garcia Middle School ',
  },
  {
    title: 'The Student Engagement:',
    quote: `I never have to beg my students to participate if it's in a Curipod. They hear the music start, and they are there.`,
    name: 'Christie Cloud, Coweta County Schools',
  },
  {
    title: 'The Students Experience:',
    quote:
      'My experience using the Curipod platform is the best because you can create your opinions and AI gives you feedback about how you can make your writing better.',

    name: '6th Grade Student from Garcia Middle School ',
  },
  {
    title: 'On Student Engagement:',
    quote:
      'The most important thing is that the students loved it and it was a way to get every student involved in the lesson.',

    name: 'Curipod First Time User',
  },
  {
    title: 'On Student Engagement:',
    quote: 'OMG this is amazing! So engaging I am in tears.',

    name: 'Curipod First Time User',
  },
  {
    title: 'On the Teaching Experience:',
    quote:
      'It was an amazing teaching experience. My students were engaged on slides that involved conversation. They were using language taught in that content area. They loved being able to respond to a variety of questions. They all voted to use this again.',

    name: 'Teacher at Portsmouth Public Schools',
  },
  {
    title: 'On AI Generation - Saving Time:',
    quote:
      "I liked that I didn't have to come up with a lesson, I just typed it in and AI developed a review for me for my Fossil Fuels unit. All the information was accurate to what I had taught during the unit! It was awesome.",

    name: 'Curipod First Time User',
  },
  {
    title: 'On AI feedback:',
    quote: 'AI feedback is a game changer…. The experience was mind blowing',
    name: 'Curipod First Time User',
  },
];

const TeacherInspiration = () => {
  const currentInspirationIndex = useRef<number>(0);
  const [renderedInspirationIndex, setRenderedInspirationIndex] = useState<number>(0);
  const intervalRef = React.useRef<NodeJS.Timer | null>(null);
  const handleSetCurrentInspirationIndex = (index: number) => {
    if (index >= inspiration.length - 1) {
      currentInspirationIndex.current = 0;
      setRenderedInspirationIndex(0);
      return;
    }
    currentInspirationIndex.current = index;
    setRenderedInspirationIndex(index);
  };
  useEffect(() => {
    if (intervalRef.current) clearInterval(intervalRef.current);
    const newInterval = setInterval(() => {
      handleSetCurrentInspirationIndex(currentInspirationIndex.current + 1);
    }, 10000);
    if (!intervalRef.current) intervalRef.current = newInterval;
    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, []);

  return (
    <CenteredSection>
      <Title>See what teachers & students are saying</Title>

      <TeacherInspirationContainer>
        <TecherInspirationContainerQuotes>
          {inspiration.map((inspo, i) => {
            return (
              <TeacherInspirationWrapper
                $isVisible={renderedInspirationIndex === i}
                key={i}
              >
                <div key={i}>
                  <InspoTitle>{inspo.title}</InspoTitle>
                  <TeacherInspirationDiv>
                    <p style={{ textAlign: 'left' }}>&ldquo;{inspo.quote}&rdquo;</p>
                    <p style={{ fontWeight: 400, textAlign: 'left', fontSize: 18 }}>
                      {inspo.name}
                    </p>
                  </TeacherInspirationDiv>
                </div>
              </TeacherInspirationWrapper>
            );
          })}
        </TecherInspirationContainerQuotes>

        <Dots>
          {inspiration.map((inspo, i) => {
            return (
              <DotButton
                key={i}
                $isActive={renderedInspirationIndex === i}
                onClick={() => {
                  handleSetCurrentInspirationIndex(i);
                }}
              ></DotButton>
            );
          })}
        </Dots>
      </TeacherInspirationContainer>
    </CenteredSection>
  );
};

const TeacherInspirationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 1000px;
  margin: 0;
  position: relative;
  height: 400px;

  @media (max-width: 550px) {
    padding: 0px 10px;
  }
`;
const TecherInspirationContainerQuotes = styled.div`
  display: flex;
  justify-content: center;
  height: 500px;
`;
const TeacherInspirationWrapper = styled.div<{ $isVisible: boolean }>`
  position: absolute;
  padding: 20px;
  width: 100%;
  justify-items: start;
  gap: 20px;
  display: flex;
  justify-content: center;
  max-height: 500px;

  ${({ $isVisible }) =>
    $isVisible
      ? `
    opacity: 1;
    visibility: visible;
    transition: opacity 0.5s ease-in-out;
  `
      : `
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease-in-out;
  `}

  @media (max-width: 550px) {
    grid-template-columns: 1fr;
    padding: 0px 10px;
  }

  @media (max-width: 550px) {
    h1 {
      font-size: 20px;
    }

    p {
      font-size: 16px !important;
    }
  }
`;

const TeacherInspirationDiv = styled.div`
  max-width: 600px;
  width: 100%;

  p {
    font-size: 20px;
    font-weight: 300;
  }
`;

const InspoTitle = styled.h1`
  font-size: 24px;
  text-align: left;
  margin-bottom: 10px;
`;

const Dots = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 100%;
`;

const DotButton = styled(Button)<{ $isActive: boolean }>`
  visibility: visible !important;
  margin: 0 5px;
  width: 10px !important;
  height: 10px;
  border-radius: 50%;
  padding: 0px;
  background-color: ${theme.colors.greyDark};
  background-color: ${({ $isActive }) =>
    $isActive ? theme.colors.green : theme.colors.grey};
`;

export const HighlightsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  padding: 10px;
  gap: 5px;
  width: 100%;
  margin-top: 20px;
  background-color: ${theme.colors.greyLight};
  border-radius: 10px;

  h4 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  p {
    margin: 0;
  }
`;

const SchoolAndDistrictItemContainer = styled(motion.div)`
  display: grid;
  grid-template-rows: 60px 60px 60px auto;
  padding: 20px;
  position: relative;
  flex: 1;
  height: 100%;
  justify-items: center;
  border-radius: 10px;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.15);
  background: white;
  color: ${theme.colors.black};
  width: 100%;

  h3 {
    font-size: 28px;
  }

  a,
  button {
    width: 100%;
  }

  @media (max-width: 600px) {
    grid-template-rows: 60px 60px 90px auto;
    h3 {
      font-size: 20px;
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  height: 100%;
  align-items: center;

  button {
    width: 100%;
  }

  @media (max-width: 600px) {
    flex-wrap: wrap;

    button {
      width: fit-content;
      margin: 0 auto;
    }
  }
`;

export const schoolAndDistrictHighlights = [
  { bullet: <CheckSvg />, text: 'Everything on the free plan' },
  {
    bullet: <CheckSvg />,
    text:
      'Premium Content: Test Prep, ELA Immersive Package, Bilingual ESL/ELL (CCSS & TEKS aligned)',
  },
  { bullet: <CheckSvg />, text: 'Student insights' },
  { bullet: <CheckSvg />, text: 'Priority support' },
  { bullet: <CheckSvg />, text: 'Customized rubrics for AI feedback' },
  { bullet: <CheckSvg />, text: 'SSO, data privacy agreements' },
  { bullet: <CheckSvg />, text: 'Shared lesson planning spaces' },
];

export const PricingPlans = ({
  isLandingPage,
  onProductSelected,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  prices: any[];
  isLandingPage?: boolean;
  isWorkspacePaywall?: boolean;
  onProductSelected?: (product: 'free' | 'premium' | 'enterprise' | 'schoolSite') => void;
}) => {
  const { track } = useMarketingMetrics();
  const { trackEvent } = useIntercom();

  const session = useSession();
  const isAuthenticated = session.status === 'authenticated';

  const basicHighlights = [
    { bullet: <CheckSvg />, text: 'Unlimited lessons' },
    { bullet: <CheckSvg />, text: 'Unlimited lesson translations' },
    { bullet: <CheckSvg />, text: 'Download Lessons as PDF' },
    { bullet: <CheckSvg />, text: 'Basic customer support' },
    {
      bullet: <MinusSvg />,
      text:
        'Premium content not included (e.g. Test Prep, ELA Immersive Package, Bilingual ESL/ELL (CCSS & TEKS aligned))',
    },
    { bullet: <MinusSvg />, text: 'No student insights' },
  ];

  const {
    isReady,
    promptPaywall, //This is for users downgrading to free
    paywallComponent,
    userAccessBadge,
  } = usePaywallPrompt({
    startState: { screen: 'downgradeToFree' },
  });
  return (
    <CenteredSection style={{ margin: !isLandingPage ? '0px auto' : '40px auto' }}>
      {paywallComponent}
      <Title>Our plans</Title>
      <SchoolsAndDistrictsContainer style={{ marginTop: isLandingPage ? 20 : 0 }}>
        <AnimatedSchoolAndDistrictItemContainer
          onClick={() => onProductSelected && onProductSelected('free')}
        >
          <h3>Free Plan</h3>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              flex: 1,
            }}
          >
            <PriceContainer>
              <p>$0</p>
            </PriceContainer>
          </div>
          <div
            style={{
              display: 'flex',
              gap: 10,
              width: '100%',
              height: '100%',
              alignItems: 'center',
            }}
          >
            {!isAuthenticated && (
              <Button
                onClick={() => router.push('/signup')}
                styles={{ width: 'fit-content', margin: '0 auto' }}
                type="ghost"
              >
                Start for free today
              </Button>
            )}
            {(!userAccessBadge ||
              userAccessBadge.license.coarseLevel === LicenseCoarseLevels.freemium) && (
              <p style={{ width: '100%', textAlign: 'center' }}>Your current plan</p>
            )}
            {userAccessBadge?.license.coarseLevel === LicenseCoarseLevels.premium && (
              <Button
                styles={{ width: 'fit-content', margin: '0 auto' }}
                type="ghost"
                onClick={() => {
                  if (isLandingPage) {
                    isReady &&
                      userAccessBadge?.license.coarseLevel !==
                        LicenseCoarseLevels.enterprise &&
                      promptPaywall({
                        label: 'Downgrade to free plan',
                      });
                  }
                  onProductSelected && onProductSelected('free');
                }}
              >
                Downgrade to free plan
              </Button>
            )}
          </div>
          <HighlightsContainer>
            <h4>Highlights</h4>
            {basicHighlights.map((highlight, i) => (
              <div
                key={i}
                style={{
                  display: 'flex',
                  gap: 10,
                  width: '100%',
                  alignItems: 'start',
                }}
              >
                <IconWrapper height={'18px'} width={'18px'}>
                  {highlight.bullet}
                </IconWrapper>
                <p>{highlight.text}</p>
              </div>
            ))}
          </HighlightsContainer>
        </AnimatedSchoolAndDistrictItemContainer>

        <AnimatedSchoolAndDistrictItemContainer>
          <h3>School & District Plan</h3>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              flex: 1,
            }}
          >
            <PriceContainer>
              <p>Custom price</p>
            </PriceContainer>
          </div>
          {(userAccessBadge?.license.coarseLevel !== LicenseCoarseLevels.enterprise ||
            userAccessBadge?.license.fineLevel !== LicenseFineLevels.trial) && (
            <ButtonContainer>
              <Link href={'/quotes'}>
                <a
                  onClick={() => {
                    track('PricingPage.GetASchoolQuote.clicked');
                    trackEvent('GetASchoolQuoteClicked');
                  }}
                >
                  <Button>Get a school quote</Button>
                </a>
              </Link>
              <Link href={'/quotes'}>
                <a
                  onClick={() => {
                    track('PricingPage.GetADistrictQuote.clicked');
                    trackEvent('GetADistrictQuoteClicked');
                  }}
                >
                  <Button>Get a district quote</Button>
                </a>
              </Link>
            </ButtonContainer>
          )}
          {userAccessBadge?.license.coarseLevel === LicenseCoarseLevels.enterprise && (
            <p style={{ width: '100%', textAlign: 'center' }}>Your current plan</p>
          )}
          <HighlightsContainer>
            <h4>Highlights</h4>
            {schoolAndDistrictHighlights.map((highlight, i) => (
              <div
                key={i}
                style={{
                  display: 'flex',
                  gap: 10,
                  width: '100%',
                  alignItems: 'start',
                }}
              >
                <IconWrapper height={'18px'} width={'18px'}>
                  {highlight.bullet}
                </IconWrapper>
                <p>{highlight.text}</p>
              </div>
            ))}
          </HighlightsContainer>
        </AnimatedSchoolAndDistrictItemContainer>
      </SchoolsAndDistrictsContainer>
      <SmallDisclaimer>
        * If you are a <strong>small school</strong> with less than 200 students, we want
        to make Curipod affordable for you, also. Please contact{' '}
        <a
          onClick={e => {
            e.stopPropagation();
            track('PricingPage.emailSmallSchool.clicked');
          }}
          style={{ color: theme.colors.blue }}
          href="mailto:amanda.aitkens@curipod.com"
        >
          amanda.aitkens@curipod.com
        </a>{' '}
        for a small school quote.
      </SmallDisclaimer>
    </CenteredSection>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  padding: 10px;
  background-color: ${theme.colors.white};
  border-radius: 10px;
  shadow: 0px 2px 20px rgba(0, 0, 0, 0.15);
`;
const TableContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 0px;
  width: 100%;
  padding: 10px;

  justify-items: center;

  p {
    margin: 0;
    padding: 10px;
    font-weight: 400;
    text-align: center;
    border-bottom: 1px solid ${theme.colors.grey};
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: left;

    strong {
      text-align: left;
    }
  }

  h2 {
    font-size: 24px;
    margin-bottom: 0px;
    justify-self: start;
    width: 100%;
    border-bottom: 2px solid ${theme.colors.grey};
  }
`;

const FeatureGroupHeading = styled.h3`
  font-size: 20px;
  margin-bottom: 10px;
  width: 100%;
  border-bottom: 2px solid ${theme.colors.grey};
  text-align: center;
  align-self: end;
  @media (max-width: 700px) {
    font-size: 18px !important;
  }
`;

export const CompareAllFeaturesTable = () => {
  return (
    <CenteredSection style={{ marginBottom: 5 }}>
      <Title style={{ textAlign: 'center' }}>Compare all features</Title>
      <Wrapper>
        {featureCompareTableData.map((featureGroup, i) => (
          <TableContainer key={i}>
            <FeatureGroupHeading style={{ textAlign: 'left', fontSize: 24 }}>
              {featureGroup.heading}
            </FeatureGroupHeading>
            <FeatureGroupHeading>Basic</FeatureGroupHeading>
            <FeatureGroupHeading>School & District</FeatureGroupHeading>

            {featureGroup.rows.map(row => (
              <>
                <p style={{ width: '100%', alignItems: 'start', display: 'block' }}>
                  {row.feature}
                </p>
                <p style={{ width: '100%' }}>{row.basic}</p>
                <p style={{ width: '100%' }}>{row.schoolPlan}</p>
              </>
            ))}
          </TableContainer>
        ))}
      </Wrapper>
    </CenteredSection>
  );
};

export const AnimatedSchoolAndDistrictItemContainer = ({
  onClick,
  children,
}: {
  children?: React.ReactNode;
  onClick?: () => void;
}) => {
  const animation = useAnimation();

  const hoverSequence = async () => {
    await animation.start({ scale: 1.03, transition: { duration: 0.1 } });
    animation.start({ scale: 1, transition: { duration: 0.1 } });
  };
  return (
    <SchoolAndDistrictItemContainer
      animate={animation}
      onHoverStart={hoverSequence}
      onClick={() => onClick && onClick()}
    >
      {children}
    </SchoolAndDistrictItemContainer>
  );
};

export const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  p {
    font-size: 28px;
    font-weight: bold;
    margin: 0;
  }
  span {
    font-size: 14px;
    font-weight: bold;
    margin: -10px 0 0 0;
    color: ${theme.colors.iconGrey};
  }
  @media (max-width: 600px) {
    p {
      font-size: 24px;
    }
  }
`;

const SchoolsAndDistrictsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  align-items: center;
  max-width: 1128px;
  margin: 10px auto;

  @media (max-width: 900px) {
    height: fit-content;
    display: flex;
    flex-direction: column-reverse;
  }
`;

const Title = styled.h2`
  font-weight: bold;
  width: 100%;
  text-align: center;
  font-size: 36px;

  @media (max-width: 900px) {
    font-size: 24px;
  }
`;

const SmallDisclaimer = styled.p`
  font-size: 12px;
  color: ${theme.colors.iconGrey};
  margin-top: 10px;
  max-width: 800px;
  margin: 0px auto 10px auto;
  padding: 0 20px;
`;
const Container = styled.div`
  width: 100%;
`;

const GreenWrapper = styled.div<{ $isLandingPage?: boolean }>`
  background: ${theme.colors.green};
  background: ${({ $isLandingPage }) =>
    $isLandingPage ? theme.colors.green : theme.colors.background};
  width: 100%;
  padding: 1px 0px;
`;

const CenteredSection = styled.div`
  width: 100%;
  max-width: 1128px;
  margin-bottom: 30px;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;

  @media (max-width: 1128px) {
    max-width: 100%;
    padding: 0px 20px !important;
  }

  @media (max-width: 500px) {
    padding: 5px !important;
  }
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PricingPageContent: NextPageWithLayout<{ prices: any[] }> = ({ prices }) => {
  const { track } = useMarketingMetrics();

  return (
    <Container style={{ marginTop: 40 }}>
      <PricingPlans prices={prices} isLandingPage />
      <CompareAllFeaturesTable />
      <SmallDisclaimer>
        * If you are a <strong>small school</strong> with less than 200 students, we want
        to make Curipod affordable for you, also. Please contact{' '}
        <a
          onClick={e => {
            e.stopPropagation();
            track('PricingPage.emailSmallSchool.clicked');
          }}
          style={{ color: theme.colors.blue }}
          href="mailto:amanda.aitkens@curipod.com"
        >
          amanda.aitkens@curipod.com
        </a>{' '}
        for a small school quote.
      </SmallDisclaimer>
      {/* {(tenantPlan?.isFreemium() || tenantPlan?.isTrial() || !isAuthenticated) && (
        <SmallDisclaimer>
          ** For individual teachers looking to upgrade,{' '}
          <span
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={() => {
              !isAuthenticated
                ? router.push('/signup')
                : isReady && !tenantPlan?.isEnterprise() && promptPaywall();
            }}
          >
            click here
          </span>
          . Individual premium does not include school plan content and costs $18 per
          month.
        </SmallDisclaimer>
      )} */}

      <TeacherInspiration />
      <FAQSection />
    </Container>
  );
};

export default PricingPageContent;
